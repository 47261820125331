.submitButton {
  @apply text-white bg-gray-900/60 hover:bg-gray-900/80
}

.submitButtonDisabled {
  @apply text-white bg-gray-900/60 ring-gray-300
}

.textButton {
  @apply text-blue-400 hover:text-blue-400/90 hover:underline text-sm
}

/* Main card style */
.card {
  border: 0.5px #333333;
  shadow: inset 0px 0px 0px 10px #f00;
  @apply bg-gray-900 drop-shadow-lg rounded-lg;
}

/* Less padding */
.navDropdown {
  border: 0.5px #333333;
  shadow: inset 0px 0px 0px 10px #f00;
  @apply bg-gray-800 drop-shadow-lg box-border border-solid rounded-lg px-2 py-1;
}

/* Changes border when you hover */
.hoverCard {
  border: 1px;
  shadow: inset 0px 0px 0px 10px #f00;
  @apply bg-black drop-shadow-lg box-border border-solid rounded-lg p-5 border-sky-600;
}
